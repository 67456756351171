import "shared/global.css";

import { AppContext } from "shared/utils/useAppContext";
import { PreviewBar } from "shared/components/PreviewBar";
import { RewriteFrames } from "@sentry/integrations";
import { useCookies } from "shared/utils/useCookies";
import * as Sentry from "@sentry/node";
import getConfig from "next/config";
import { createInternalHref } from "clariah/utils/createInternalHref";
import { useState, useEffect } from "react";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

// err is a workaround for https://github.com/vercel/next.js/issues/8592
function MyApp({ Component, pageProps, err }) {
  const cookies = useCookies();
  const scope = "Clariah";

  const [inBrowser, setInBrowser] = useState();

  useEffect(() => setInBrowser(window.top === window.self));

  return (
    <AppContext.Provider value={{ cookies, scope, createInternalHref }}>
      {pageProps.preview && inBrowser && <PreviewBar />}
      <Component {...pageProps} err={err} />
    </AppContext.Provider>
  );
}

export default MyApp;
